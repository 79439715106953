<template>
  <base-card>
    <v-card-text>
      <v-row class="align-end">
        <v-col
          cols="12"
          sm="6"
          md="4"
          lg="3"
          class="d-flex justify-sm-end align-end pb-0 pb-sm-3"
        >
          <label class="pr-4">{{ $t('order.include') }}:</label>
          <v-checkbox
            v-model="filterOptions.isBilled"
            :label="$t('order.billed')"
            hide-details
            class="mt-0 mr-3"
          />

          <template v-if="filterOptions.status == null">
            <v-checkbox
              v-model="filterOptions.isCanceled"
              :label="$t('order.canceled')"
              hide-details
              class="mt-0 mr-3"
            />

            <v-checkbox
              v-model="filterOptions.isRejected"
              :label="$t('order.rejected')"
              hide-details
              class="mt-0"
            />
          </template>
        </v-col>

        <v-col
          cols="12"
          sm="6"
          md="3"
          lg="3"
        >
          <v-select
            v-model="filterOptions.status"
            :items="statusList"
            :label="$t('common.status')"
            hide-details
            item-text="label"
            item-value="value"
            clearable
          />
        </v-col>

        <v-col
          cols="12"
          sm="6"
          md="3"
          lg="3"
        >
          <v-select
            v-model="filterOptions.network"
            :items="networkList"
            :label="$t('price.network')"
            hide-details
            item-text="item"
            item-value="item"
            clearable
          />
        </v-col>

         <v-col
          cols="12"
          sm="6"
          lg="3"
          offset-md="4"
          offset-lg="0"
        >
          <v-text-field
            v-model="filterOptions.spReference"
            hide-details
            clearable
            :label="$t('order.spReference')"
          />
        </v-col>
      </v-row>

      <v-row class="align-end">
        <v-col
          cols="12"
          sm="6"
          md="3"
        >
          <v-text-field
            v-model="filterOptions.email"
            hide-details
            clearable
            :label="$t('common.email')"
          />
        </v-col>

        <v-col
          cols="12"
          sm="6"
          md="3"
        >
          <v-text-field
            v-model="filterOptions.ssn"
            hide-details
            clearable
            :label="$t('order.socialSecurityNumber')"
          />
        </v-col>

        <v-col
          cols="12"
          sm="6"
          md="3"
        >
          <v-text-field
            v-model="filterOptions.city"
            hide-details
            clearable
            :label="$t('address.city')"
          />
        </v-col>

        <v-col
          cols="12"
          sm="6"
          md="4"
          lg="3"
        >
          <v-text-field
            v-model="filterOptions.street"
            hide-details
            clearable
            :label="$t('address.streetName')"
          />
        </v-col>
      </v-row>

      <v-row class="align-end">
        <v-col
          cols="12"
          class="d-flex justify-center"
        >
          <v-btn
            class="primary px-4 mr-4"
            @click="currentPage = 1; loadOrderList()"
          >
            {{ $t("common.search") }}
          </v-btn>

          <v-btn
            class="info px-4 mr-4"
            @click="downloadCsv">
            {{ $t("order.download") }}
          </v-btn>

          <v-btn
            class="error px-4"
            @click="resetFilter">
            {{ $t("common.reset") }}
          </v-btn>
        </v-col>
      </v-row>
      <v-data-table
        :headers="headers"
        :items="orderList"
        :items-per-page="paginationLimit"
        hide-default-footer
        class="eg-table-bnone eg-table-td-py-md mt-2 px-4"
      >
        <template v-slot:item.index="{item}">
          <v-menu
            top
            close-on-content-click
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                fab
                x-small
                dark
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-list dense>
              <v-list-item @click="gotoOrderDetailPage(item.id)">
                <v-list-item-icon>
                  <v-icon color="orange darken-4 white--text">mdi-printer-eye</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{ $t('order.viewDetail') }}</v-list-item-title>
              </v-list-item>

              <template v-if="item.status === OrderStatusEnum.APPROVED || item.status === OrderStatusEnum.DELIVERED">
                <v-divider />
                <v-list-item @click="viewDocument(item.id)">
                  <v-list-item-icon>
                    <v-icon color="success">mdi-text-box-check</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>{{ $t("order.document") }}</v-list-item-title>
                </v-list-item>
              </template>

              <template v-if="item.status === OrderStatusEnum.PENDING || item.status === OrderStatusEnum.APPROVED">
                <v-divider />
                <v-list-item @click="rescanOrder(item.id)">
                  <v-list-item-icon>
                    <v-icon color="primary">mdi-lock-reset</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>{{ $t("order.rescan") }}</v-list-item-title>
                </v-list-item>

                <template v-if="item.status === OrderStatusEnum.PENDING">
                  <v-divider />
                  <v-list-item @click="retryOrder(item.id)">
                    <v-list-item-icon>
                      <v-icon color="info">mdi-restart-alert</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>{{ $t("order.retryOrder") }}</v-list-item-title>
                  </v-list-item>
                </template>

                <template v-if="(item.network.network === 'IP-ONLY' || item.network.network === 'OPENUNIVERSE') && item.status === OrderStatusEnum.APPROVED">
                  <v-divider />
                  <v-list-item @click="markDelivered(item.id)">
                    <v-list-item-icon>
                      <v-icon color="purple white--text">mdi-moped</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>{{ $t("order.markDelivered") }}</v-list-item-title>
                  </v-list-item>
                </template>
              </template>

              <template v-if="item.status !== OrderStatusEnum.CANCELED && item.status !== OrderStatusEnum.DELIVERED">
                <v-divider />
                <v-list-item @click="onResendOrderConfirmEmail(item.id)">
                  <v-list-item-icon>
                    <v-icon color="primary">mdi-email-fast</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>{{ $t("order.resendOrderConfirmationEmail") }}</v-list-item-title>
                </v-list-item>
              </template>
            </v-list>
          </v-menu>
        </template>

        <template v-slot:item.createdAt="{item}">
          <span>{{ util.formatDateTime(item.createdAt) }}</span>
        </template>

        <template v-slot:header.startCost="{ header }">
          {{ header.text }} <br>
          ({{ $t('price.start') }} / {{ $t('price.monthly') }})
        </template>

        <template v-slot:item.startCost="{item}">
          {{ item.startCost }} / {{ item.monthlyCost }}
        </template>

        <template v-slot:item.street="{item}">
          <span>{{ item.street }} {{ item.streetNumber }} {{ item.streetLetter }}</span>
          <br />
          <span>{{ util.formatPostalCode(item.postalCode) }} {{ item.locality }}</span>
        </template>

        <template v-slot:item.orderLocalContactList="{item}">
          <ol class="pl-3">
            <li
              v-for="(contactInfo, index) in item.orderLocalContactList"
              :key="index"
              class="my-3"
            >
              <template v-if="contactInfo.name">
                <span>{{ $t('common.name') }}: {{ contactInfo.name }}</span><br />
              </template>

              <template v-if="contactInfo.email">
                <span>{{ $t('common.email') }}: {{ contactInfo.email }}</span><br />
              </template>

              <template v-if="contactInfo.phoneNumber">
                <span>{{ $t('common.phoneNumber') }}: {{ contactInfo.phoneNumber }}</span><br />
              </template>
            </li>
          </ol>
        </template>

        <template v-slot:item.status="{item}">
          <v-chip
            v-if="item.status === OrderStatusEnum.REQUEST"
            class="px-4"
            color="secondary white--text"
          >
            <v-icon left>
              mdi-send
            </v-icon>
            {{ $t('order.request') }}
          </v-chip>

          <v-chip
            v-else-if="item.status === OrderStatusEnum.PENDING"
            class="px-4"
            color="orange darken-4 white--text"
          >
            <v-icon left>
              mdi-account-clock
            </v-icon>
            {{ $t('order.pending') }}
          </v-chip>

          <template v-else-if="item.status === OrderStatusEnum.APPROVED || item.status === OrderStatusEnum.ADMIN_APPROVED">
            <v-chip
              class="px-4"
              color="primary white--text"
            >
              <v-icon left>
                mdi-checkbox-marked-circle
              </v-icon>
              {{ getApproveStatusText(item) }}
            </v-chip>
          </template>

          <v-chip
            v-if="item.status === OrderStatusEnum.REJECTED"
            class="px-4"
            color="error"
          >
            <v-icon left>
              mdi-cancel
            </v-icon>
            {{ $t('order.rejected') }}
          </v-chip>

          <v-chip
            v-if="item.status === OrderStatusEnum.DELIVERED"
            class="px-4"
            color="purple white--text"
          >
            <v-icon left>
              mdi-moped
            </v-icon>
            {{ $t('order.delivered') }}
          </v-chip>

          <v-chip
            v-if="item.subscriptionId"
            class="px-4 mt-1"
            color="success white--text"
          >
            <v-icon left>
              mdi-moped
            </v-icon>
            {{ $t('order.billed') }}
          </v-chip>

          <v-chip
            v-if="item.status === OrderStatusEnum.CANCELED"
            class="px-4"
            color="error"
          >
            <v-icon left>
              mdi-cancel
            </v-icon>
            {{ $t('order.canceled') }}
          </v-chip>
        </template>

        <template v-slot:item.networkInfo="{item}">
          <span><strong>{{ item.network.network }}</strong></span>
          <br>
          <span>{{ item.networkInfo }}</span>
        </template>
      </v-data-table>
    </v-card-text>

    <v-card-actions class="justify-center">
      <v-pagination
        v-model="currentPage"
        :length="totalPages"
        color="primary"
        :total-visible="7"
        circle
      />
    </v-card-actions>
  </base-card>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import util from '@/utils'
  import {
    showSuccessDialog,
    showErrorDialog,
  } from '@/utils/swalDialog'
  import OrderStatusEnum from '@/utils/orderStatus'

  export default {
    metaInfo: {
      title: 'Network Orders',
    },

    data () {
      return {
        orderList: [],
        paginationLimit: 10,
        currentPage: 1,
        totalPages: 0,
        headers: [],
        OrderStatusEnum: OrderStatusEnum,
        util: util,
        networkList: [
          'IP-ONLY',
          'OPENINFRA',
          'OPENUNIVERSE',
          'SOLLENTUNA',
          'STOKAB',
          'ZITIUS'
        ],
        statusList: [],
        filterOptions: {
          network: null,
          city: '',
          street: '',
          status: null,
          spReference: '',
          isBilled: false,
          isCanceled: false,
          isRejected: false,
          email: '',
          ssn: '',
        },
      }
    },

    computed: {
      ...mapGetters({
        isAdminUser: 'isAdminUser',
        isPartnerAdminUser: 'isPartnerAdminUser',
        isCustomer: 'isCustomer',
      }),
    },

    watch: {
      currentPage(val) {
        this.currentPage = val
        this.loadOrderList()
      }
    },

    mounted () {
      this.statusList = [{
        value: null,
        label: this.$t('common.active'),
      }, {
        value: 0,
        label: this.$t('order.request'),
      }, {
        value: 1,
        label: this.$t('order.pending'),
      }, {
        value: 2,
        label: this.$t('order.approved'),
      }, {
        value: 3,
        label: this.$t('order.rejected'),
      }, {
        value: 4,
        label: this.$t('order.delivered'),
      }, {
        value: 5,
        label: this.$t('order.adminApproved'),
      }, {
        value: 6,
        label: this.$t('order.canceled'),
      }]
      this.headers = [
        { value: 'createdAt', text: this.$t('common.createdAt'), width: 120, sortable: false },
        { value: 'socialSecurityNumber', text: 'SSN', width: 100, sortable: false },
        { value: 'networkInfo', text: this.$t('price.network'), width: 240, sortable: false },
        { value: 'startCost', text: this.$t('common.cost'), width: 160, sortable: false },
        { value: 'street', text: this.$t('address.address'), width: 180, sortable: false },
        { value: 'expectedDelivery', text: this.$t('price.expectedDelivery'), width: 160, sortable: false },
        { value: 'orderLocalContactList', text: this.$t('order.contactsInfo'), sortable: false },
        { value: 'status', text: this.$t('order.status'), width: 140, sortable: false },
      ]

      if (!this.isCustomer) {
        this.headers.splice(0, 0, {
          value: 'index',
          text: this.$t('common.action'),
          width: 50,
          sortable: false,
        })
      }

      this.loadOrderList()
    },

    methods: {
      ...mapActions({
        fetchOrdersList: 'fetchOrdersList',
        rescanOrderInfo: 'rescanOrderInfo',
        retryOrderInfo: 'retryOrderInfo',
        markDeliveredOrderInfo: 'markDeliveredOrderInfo',
        setLoading: 'setLoading',
        setLoadingText: 'setLoadingText',
        resendOrderConfirmationEmail: 'resendOrderConfirmationEmail',
        downloadOrder: 'downloadOrder',
      }),

      async loadOrderList() {
        const offset = (this.currentPage - 1) * this.paginationLimit
        const query = {
          network: this.filterOptions.network,
          status: this.filterOptions.status,
          city: this.filterOptions.city,
          street: this.filterOptions.street,
          spReference: this.filterOptions.spReference,
          isBilled: this.filterOptions.isBilled,
          isCanceled: this.filterOptions.isCanceled,
          isRejected: this.filterOptions.isRejected,
          email: this.filterOptions.email,
          ssn: this.filterOptions.ssn,
          offset,
          limit: this.paginationLimit,
        }

        this.setLoadingText(`${this.$t('common.loading')} ${this.$t('common.data')}...`)
        this.setLoading(true)
        try {
          const response = await this.fetchOrdersList(query)

          this.totalPages = Math.ceil(response.count / this.paginationLimit)
          this.orderList = response.rows.map((order, index) => {
            const networkInfo = `${this.$t('price.networkSpeed', {
              speed: order.network.speed,
            })} ${order.network.type} / ${order.network.term} ${this.$t(
              'order.month'
            )}`

            let htmlContactInfo = ''
            const keys = ['name', 'email', 'phoneNumber']

            order.orderLocalContactList.map((contactInfo) => {
              let content = ''

              for (const key in contactInfo) {
                if (!keys.includes(key)) {
                  continue
                }

                if (!contactInfo[key]) {
                  continue
                }

                content += `${this.$t(`common.${key}`)}: ${
                  contactInfo[key]
                }<br />`
              }

              htmlContactInfo += content ? `<li><div>${content}</div></li>` : ''
            })

            htmlContactInfo = htmlContactInfo
              ? `<ol class="text-list">${htmlContactInfo}</ol>`
              : ''

            return {
              ...order,
              index: offset + index + 1,
              createdAt: util.formatDateTime(order.createdAt, 'MMM DD YYYY hh:mm A'),
              expectedDelivery: util.formatDateTime(order.expectedDelivery),
              startCost: `${order.startCost} SEK`,
              monthlyCost: `${order.monthlyCost} SEK`,
              networkInfo,
              contactsInfo: htmlContactInfo,
            }
          })
        } catch (error) {
          const errorText = util.getErrorResponse(error)
          showErrorDialog(this.$t('common.error'), errorText)
        }
        this.setLoading(false)
      },

      gotoOrderDetailPage(orderId) {
        this.$router.push(`/order-price/${orderId}`)
      },

      viewDocument(orderId) {
        window.open(`./_order_pdfs/${orderId}.pdf`, '_blank')
      },

      getApproveStatusText(order) {
        if (order.status === OrderStatusEnum.APPROVED) {
          return this.$t('order.approved')
        }

        return this.$t('order.adminApproved')
      },

      async rescanOrder(orderId) {
        this.setLoadingText(this.$t('order.rescanningOrderInfo'))
        this.setLoading(true)
        try {
          const response = await this.rescanOrderInfo({
            orderId,
          })

          showSuccessDialog(this.$t('order.rescanOrderSuccessfully'))

          await this.loadOrderList()
        } catch (error) {
          const errorText = util.getErrorResponse(error)
          showErrorDialog(this.$t('common.error'), errorText)
        }

        this.setLoading(false)
      },

      async retryOrder(orderId) {
        this.setLoadingText(this.$t('order.retryingOrderInfo'))
        this.setLoading(true)
        try {
          const response = await this.retryOrderInfo({
            orderId,
          })

          showSuccessDialog(this.$t('order.retryOrderSuccessfully'))

          await this.loadOrderList()
        } catch (error) {
          const errorText = util.getErrorResponse(error)
          showErrorDialog(this.$t('common.error'), errorText)
        }

        this.setLoading(false)
      },

      async markDelivered(orderId) {
        this.setLoadingText(this.$t('order.markingOrderInfo'))
        this.setLoading(true)
        try {
          const response = await this.markDeliveredOrderInfo({
            orderId,
          })

          showSuccessDialog(this.$t('order.markOrderSuccessfully'))

          await this.loadOrderList()
        } catch (error) {
          const errorText = util.getErrorResponse(error)
          showErrorDialog(this.$t('common.error'), errorText)
        }

        this.setLoading(false)
      },

      async onResendOrderConfirmEmail(orderId) {
        this.setLoadingText(this.$t('order.resendingOrderConfirmationEmail'))
        this.setLoading(true)
        try {
          const response = await this.resendOrderConfirmationEmail({
            orderId,
          })

          showSuccessDialog(this.$t('order.resentOrderEmailSuccessfully'))
        } catch (error) {
          const errorText = util.getErrorResponse(error)
          showErrorDialog(this.$t('common.error'), errorText)
        }

        this.setLoading(false)
      },

      async downloadCsv() {
        this.setLoadingText(this.$t('order.downloadingOrders'))
        this.setLoading(true)
        try {
          const response = await this.downloadOrder()
          this.downloadJSONToCSV(response.csv, 'orders.csv')

        } catch (error) {
          const errorText = util.getErrorResponse(error)
          showErrorDialog(this.$t('common.error'), errorText)
        }

        this.setLoading(false)
      },

      downloadJSONToCSV (jsonData, fileName) {
        const file = `data:text/html;charset=utf-8,${jsonData}`
        const data = encodeURI(file)
        const link = document.createElement('a')
        link.setAttribute('href', data)
        link.setAttribute('download', fileName)
        link.click()
      },

      resetFilter() {
        this.filterOptions = {
          network: null,
          city: '',
          street: '',
          status: null,
          spReference: '',
          isBilled: false,
          isCanceled: false,
          isRejected: false,
          email: '',
          ssn: '',
        }

        if (this.currentPage === 1) {
          this.loadOrderList()
          return
        }

        this.currentPage = 1
      },
    },
  }
</script>
